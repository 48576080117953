$(() => {
  const $dgdFileInputs = $('.dgd-file');

  $dgdFileInputs.each((i, item) => {
    const $baseFileInput = $(item).find('input[type="file"]');
    const $originalText = $(item).find('.dgd-file-text').clone(true);
    // Use unnamed function to get the correct 'this' element
    // eslint-disable-next-line func-names
    $baseFileInput.on('change', function () {
      const { files } = this;
      const $currentText = $(item).find('.dgd-file-text');
      $currentText.html('');
      $currentText.removeClass('multiple-texts');

      if (files.length) {
        $currentText.addClass('multiple-texts');
        Array.from(files).forEach((file) => {
          $currentText.append(`<span>${file.name}</span>`);
        });
      } else {
        $currentText.append($originalText.clone(true));
      }
    });
  });

  function openFilerPopup(e) {
    e.preventDefault();
    const url = e.currentTarget.href;
    window.open(`${url}&_popup=1`, e.currentTarget.id, 'width=550,height=550,left=150,top=200,toolbar=0,status=0,');
  }

  $('.js-related-lookup').on('click', openFilerPopup);
  $('.js-related-edit').on('click', openFilerPopup);
});
