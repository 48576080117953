// From cms/static/cms/js/widgets/forms.apphookselect.js

import { DGDSelect } from './selects';

function handlPageAppHookSelects() {
  const apphooksConfiguration = window.apphooks_configuration || {};
  const $appHooks = $('#application_urls, #id_application_urls');
  const $selected = $appHooks.find('option:selected');
  const $appNsRow = $('.form-group:has(#id_application_namespace)');
  const $appNs = $appNsRow.find('#application_namespace, #id_application_namespace');
  const $appCfgsRow = $('.js-form-row:has(#application_configs)');
  const $appCfgs = $appCfgsRow.find('#application_configs, #id_application_configs');
  const $appCfgsAdd = $appCfgsRow.find('#add_application_configs');
  const $originalNs = $appNs.val();

  // Shows / hides namespace / config selection widgets depending on the user input
  // eslint-disable-next-line func-names
  $appHooks.setupNamespaces = function () {
    const $opt = $(this).find('option:selected');

    if ($appCfgs.length > 0 && apphooksConfiguration[$opt.val()]) {
      $appCfgs.html('');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < apphooksConfiguration[$opt.val()].length; i++) {
        let selectedCfgs = '';

        if (apphooksConfiguration[$opt.val()][i][0] === window.apphooks_configuration_value) {
          selectedCfgs = 'selected="selected"';
        }
        $appCfgs.append(
          `<option ${selectedCfgs} value="${apphooksConfiguration[$opt.val()][i][0]}">
            ${apphooksConfiguration[$opt.val()][i][1]}
          </option>`
        );
      }
      // Here we check if we are on django>=1.8 by checking if the method introduced in that version
      // exists, and if it does - we add `_popup` ourselves, because otherwise the popup with
      // apphook creation form will not be dismissed correctly
      $appCfgsAdd.attr('href', window.apphooks_configuration_url[$opt.val()] + (window.showRelatedObjectPopup ? '?_popup=1' : ''));
      $appCfgsRow.removeClass('d-none');
      $appNsRow.addClass('d-none');
      DGDSelect.updateExistingSelectOptions($appCfgs);
    } else {
      $appCfgsRow.addClass('d-none');
      if ($opt.data('namespace')) {
        $appNsRow.removeClass('d-none');
      } else {
        $appNsRow.addClass('d-none');
      }
    }
  };

  // Hide the namespace widgets if its not required.
  $appHooks.setupNamespaces();

  // Show it if we change to an app_hook that requires a namespace
  // eslint-disable-next-line func-names
  $appHooks.on('change', function () {
    const $self = $(this);
    const $opt = $self.find('option:selected');

    $appHooks.setupNamespaces();

    // If we clear the app_hook, clear out the app_namespace too
    if (!$self.val()) {
      $appNs.val('');
      $appNs.removeAttr('value');
    }

    // When selecting back the original apphook we try
    // to restore the original configuration
    if ($selected.val() === $opt.val()) {
      if ($originalNs) {
        $appNs.val($originalNs);
      }
    } else if ($opt.data('namespace')) {
      // If new apphook has a namespace, suggest the default
      $appNs.val($opt.data('namespace'));
    } else {
      // Cleanup the whole thing
      $appNs.val('');
      $appNs.removeAttr('value');
    }
  });
}

$(handlPageAppHookSelects);
