const FEEDBACK_FORM_SELECTOR = '.feedback-form';

const changeReasonCollapseVisibility = (formId, visibility) => {
  const $collapse = $(`#feedback-${formId}-reason`);
  if ($collapse.length) {
    $collapse.collapse(visibility);
  }
};

const changeSubmitCollapseVisibility = (formId, visibility) => {
  const $collapse = $(`#feedback-${formId}-submit`);
  if ($collapse.length) {
    $collapse.collapse(visibility);
  }
};

const changeSuccessCollapseVisibility = (formId, visibility) => {
  const $collapse = $(`#feedback-${formId}-success`);
  if ($collapse.length) {
    $collapse.collapse(visibility);
  }
};

const mainResponseChangeHandler = (formId, event) => {
  const form = event.target.closest('form');
  if (form.elements.answer.value === '1') {
    changeReasonCollapseVisibility(formId, 'hide');
    changeSubmitCollapseVisibility(formId, 'show');
  } else if (form.elements.answer.value === '0') {
    changeReasonCollapseVisibility(formId, 'show');
    changeSubmitCollapseVisibility(formId, 'show');
  }
};

const submitHandler = (formId) => {
  changeReasonCollapseVisibility(formId, 'hide');
  changeSubmitCollapseVisibility(formId, 'hide');
  changeSuccessCollapseVisibility(formId, 'show');
};

const resetHandler = (formId) => {
  changeReasonCollapseVisibility(formId, 'hide');
  changeSubmitCollapseVisibility(formId, 'hide');
  changeSuccessCollapseVisibility(formId, 'hide');
};


$(() => {
  const feedbackForms = document.querySelectorAll(FEEDBACK_FORM_SELECTOR);

  feedbackForms.forEach((form) => {
    const formId = form.dataset.feedbackInstanceId;
    const positiveResponseInput = document.getElementById(`feedback-${formId}-answer-yes`);
    const negativeResponseInput = document.getElementById(`feedback-${formId}-answer-no`);

    positiveResponseInput.addEventListener('change', mainResponseChangeHandler.bind(null, formId));
    negativeResponseInput.addEventListener('change', mainResponseChangeHandler.bind(null, formId));
    form.addEventListener('submit', submitHandler.bind(null, formId));
    form.addEventListener('reset', resetHandler.bind(null, formId));
  });
});
