// as of Django 2.x we need to check where jQuery is
let djQuery = window.$;

if (typeof django !== 'undefined') {
  // eslint-disable-next-line no-undef
  djQuery = django.jQuery;
}
function handleFilerWidget($) {
  function clearFilerData() {
    const clearer = $(this);
    const container = clearer.closest('.filerFile');
    const input = container.find(':input');
    const thumbnail = container.find('.thumbnail_img');
    const description = container.find('.description_text');
    const addImageButton = container.find('.lookup');
    const editImageButton = container.find('.edit');
    const dropzoneMessage = container.siblings('.dz-message');
    const hiddenClass = 'hidden';

    clearer.addClass(hiddenClass);
    input.val('');
    thumbnail.addClass(hiddenClass);
    thumbnail.parent('a').removeAttr('href');
    addImageButton.removeClass('related-lookup-change');
    editImageButton.removeClass('related-lookup-change');
    dropzoneMessage.removeClass(hiddenClass);
    description.empty();
  }


  $('.filerFile .vForeignKeyRawIdAdminField').attr('type', 'hidden');
  // if this file is included multiple time, we ensure that filer_clear is attached only once.
  $(document).off('click.filer', '.filerFile .filerClearer', clearFilerData)
    .on('click.filer', '.filerFile .filerClearer', clearFilerData);
}

djQuery(handleFilerWidget);
