window.addEventListener('load', () => {
  document
    .querySelectorAll('.ugly-ellipsis')
    .forEach((item) => {
      const originalText = item.innerText;
      const words = item.innerHTML.split(' ');

      while (item.scrollHeight > item.offsetHeight) {
        words.pop();
        // eslint-disable-next-line no-param-reassign
        item.innerHTML = `${words.join(' ')}...`;
      }

      // eslint-disable-next-line no-param-reassign
      item.title = originalText;
    });
});
