const SUBMIT_ON_CHANGE_SELECTOR = '.submit-on-change';

$(() => {
  document.querySelectorAll(SUBMIT_ON_CHANGE_SELECTOR).forEach((control) => {
    const { form } = control;

    if (!form) return;

    $(control).on('change', () => {
      form.submit();
    });
  });
});
