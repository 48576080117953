/**
 * Hide the received element
 * @param {HTMLElement} el - The element to hide
 */
function hideElement(el) {
  el.classList.add('d-none');
}

/**
 * Show the received element
 * @param {HTMLElement} el - The element to display
 */
function showElement(el) {
  el.classList.remove('d-none');
}

/**
 * Toggles visibility of an specific element if the element has an identifier
 * that matches the text.
 * @param {HTMLElement} element - The element to hide/show
 * @param {string} textToMatch - The text to use as a toggle condition
 */
function toggleElementVisibilityByIdentifier(element, textToMatch) {
  const elementIdentifier = element.dataset.actionIdentifier;
  if (!elementIdentifier.toLowerCase().includes(textToMatch)) {
    hideElement(element);
  } else {
    showElement(element);
  }
}

/**
 * Handles whether to show or hide multiple action elements using the user inputted text
 * @param {string} userInput - Text inputted by the user
 */
function handleUserInputFilter(userInput) {
  const actionElements = document.querySelectorAll('.j-institution');
  if (!actionElements) {
    return;
  }

  if (userInput.length === 0) {
    actionElements.forEach(showElement);
  } else if (userInput.length > 2) {
    actionElements.forEach((element) => {
      toggleElementVisibilityByIdentifier(element, userInput);
    });
  }
}

/**
 * Inits input to filter the institutions list elements
 */
function initInstitutionsFilterInput() {
  const input = document.getElementById('institution-search');

  if (!input) {
    return;
  }

  input.addEventListener('input', (e) => {
    const valueToFilter = e.target.value.toLowerCase();
    handleUserInputFilter(valueToFilter);
  });
}

$(() => {
  initInstitutionsFilterInput();
});
