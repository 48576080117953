/*
  This file uses the google object generated by using Google libraries. Because the lib
  is called by CDN and added globally to all behaviors, we don't create a google object to
  avoid errors with other scripts. Instead, just call the google instance and disable the
  no-param-reassign linter rule.
 */

let currentTreemapData = {};
let currentTreemap = {};

const getInteractionsMapContainer = () => document.getElementById('interactionsGraph');

/**
 * Changes the received data to render in the treemap, modifying the 'Institution' values
 * of the repeated elements to avoid errors.
 * FIXME: This function shouldn't be needed, backend should provide all data formated.
 *        Remove it when backend is fixed.
 * @param {object array} data - Array of elements to show in the treemap
 * @returns a formatted version of the received data, without repeated elements
 */
function formatTreemapData(data) {
  return data.map((d, index, arr) => {
    const isRepeated = arr.some((d2, index2) => d2[0] === d[0] && index !== index2);
    if (isRepeated) {
      // eslint-disable-next-line no-param-reassign
      d[0] = `${d[0]}${index}`;
    }
    return d;
  });
}

/**
 * Renders the treemap using Google Charts library
 * @param { object array} data - Array of elements to show in the treemap
 * @returns {object} google treemap reference - The result of calling
 *  google.visualization.DataTable and google.visualization.TreeMap.
 *  Contains references to the treemap element and data, alongisde the methods to
 *  manipulate them.
 */
function drawChart(data) {
  const formattedData = formatTreemapData(data);

  // eslint-disable-next-line no-undef
  const charData = new google.visualization.DataTable();
  charData.addColumn('string', 'Institution');
  charData.addColumn('string', 'Parent');
  charData.addColumn('number', 'Volume');

  charData.addRows(formattedData);

  const options = {
    highlightOnMouseOver: true,
    maxDepth: 1,
    maxPostDepth: 2,
    minHighlightColor: '#8c6bb1',
    midHighlightColor: '#9ebcda',
    maxHighlightColor: '#edf8fb',
    minColor: '#B9E1EE',
    midColor: '#3A6EAE',
    maxColor: '#294090',
    headerHeight: 50,
    height: 500,
    width: getInteractionsMapContainer().width,
    useWeightedAverageForAggregation: true,
    fontSize: 14
  };

  // eslint-disable-next-line no-undef
  const tree = new google.visualization.TreeMap(getInteractionsMapContainer());
  tree.draw(charData, options);
  return { charData, tree };
}

/**
 * Inits the intractions treemap, loading the google charts library and
 * fetching the data from the json.
 */
function initInteractionsTreemap() {
  // eslint-disable-next-line no-undef
  google.charts.load('current', { packages: ['treemap'] });
  const treemapContainer = getInteractionsMapContainer();
  if (!treemapContainer) return;

  const data = document.getElementById('treemap_data');

  if (data) {
    const jsonData = JSON.parse(data.textContent);
    // eslint-disable-next-line no-undef
    google.charts.setOnLoadCallback(() => {
      const result = drawChart(jsonData);
      currentTreemapData = result.charData;
      currentTreemap = result.tree;
    });
  }
}

/**
 * Inits the interactions select, adding event and validations
 */
function initInteractionsSelector() {
  const selector = document.getElementById('interactionsGraphSelector');
  if (!selector) return;

  selector.value = window.location.pathname;
  $(selector).on('change', (e) => {
    if (window.location.pathname !== e.target.value) {
      window.location.replace(`${window.location.origin}${e.target.value}`);
    }
  });
}

/**
 * Handles the traversing through the map when a link is clicked.
 * @param {Event} e - Click event
 */
function handleActionLinkClick(e) {
  e.preventDefault();
  if (!currentTreemapData || !currentTreemap) {
    console.error('Global treemap or treemap data are not available');
    return;
  }

  const { currentTarget } = e;
  const rowIndex = currentTreemapData.getFilteredRows([{
    column: 0,
    test: value => value.includes(currentTarget.dataset.actionIdentifier)
  }]);
  if (rowIndex) {
    currentTreemap.setSelection([{ row: rowIndex }]);
  }
}

/**
 * Inits the links to navigate through the treemap.
 */
function initInteractionActionLinks() {
  const links = document.querySelectorAll('.j-treemap-node-selector');
  links.forEach((link) => {
    link.addEventListener('click', handleActionLinkClick);
  });
}

$(() => {
  initInteractionsTreemap();
  initInteractionsSelector();
  initInteractionActionLinks();
});
