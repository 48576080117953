import '../scss/main.scss';
import App from './app';

import './behaviors/navbar';
import './behaviors/status';
import './behaviors/input-file';
import './behaviors/input-time-picker';
import './behaviors/input-rut';
import './behaviors/institutions-filter';
import './behaviors/interactions-treemap';
import './behaviors/pagechange.apphookselect';
import './behaviors/selects';
import './behaviors/social-share';
import './behaviors/ugly-ellipsis';
import './behaviors/scroll-observer';
import './behaviors/submit-on-change';
import './behaviors/use-redirect-modal';
import './behaviors/carousel';
import './behaviors/feedback';

// FILER - To replicate the functionality outside of the admin module
import './behaviors/filer/libs/mediator.min';
import './behaviors/filer/addons/popup_handling';
import './behaviors/filer/addons/widget';
import './behaviors/filer/base';

$(() => {
  $('.alert').each((i, item) => {
    App.utils.highlight($(item));
  });

  const $alert = $('.main-alert .alert');
  setTimeout(() => $alert.fadeOut(), 10000);

  $('.model-form input:text').addClass('form-control');

  $('form').submit((e) => {
    const $this = $(e.currentTarget);
    const $buttons = $this.find(':submit').not('.js-do-not-disable-on-submit');

    // disable buttons after submit to prevent disabling submit inputs
    // with values
    $buttons.addClass('disabled').prop('disabled', true);
    App.utils.showLoading($buttons);

    return true;
  });
});
