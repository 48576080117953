/* global django */

// as of Django 2.x we need to check where jQuery is
let djQuery = window.$;

if (typeof django !== 'undefined') {
  djQuery = django.jQuery;
}

function handlePopupResponse($) {
  function windowNameToId(text) {
    let modifiedText = text.replace(/__dot__/g, '.');
    modifiedText = text.replace(/__dash__/g, '-');
    return modifiedText.split('__')[0];
  }

  function dismissPopupAndReload(win) {
    document.location.reload();
    win.close();
  }

  window.dismissPopupAndReload = dismissPopupAndReload;

  function dismissRelatedImageLookupPopup(win,
    chosenId,
    chosenThumbnailUrl,
    chosenDescriptionTxt,
    chosenAdminChangeUrl
  ) {
    const id = windowNameToId(win.name);
    const lookup = $(`#${id}`);
    const container = lookup.closest('.filerFile');
    const edit = container.find('.edit');
    const image = container.find('.thumbnail_img');
    const descriptionText = container.find('.description_text');
    const clearer = container.find('.filerClearer');
    const dropzoneMessage = container.siblings('.dz-message');
    const element = container.find(':input');
    const oldId = element.value;

    element.val(chosenId);
    element.closest('.js-filer-dropzone').addClass('js-object-attached');
    if (chosenThumbnailUrl) {
      image.attr('src', chosenThumbnailUrl).removeClass('hidden');
      image.removeAttr('srcset'); // would be nicer, but much more complicate to also replace 'srcset'
    }
    descriptionText.text(chosenDescriptionTxt);
    clearer.removeClass('hidden');
    lookup.addClass('related-lookup-change');
    edit.addClass('related-lookup-change');
    if (chosenAdminChangeUrl) {
      edit.attr('href', `${chosenAdminChangeUrl}?_edit_from_widget=1`);
    }
    dropzoneMessage.addClass('hidden');

    if (oldId !== chosenId) {
      element.trigger('change');
    }
    win.close();
  }

  window.dismissRelatedImageLookupPopup = dismissRelatedImageLookupPopup;

  function dismissChangeRelatedObjectPopup(win) {
    win.close();
  }

  window.dismissChangeRelatedObjectPopup = dismissChangeRelatedObjectPopup;
}
(handlePopupResponse)(djQuery);
