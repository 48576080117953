// #BASE#
// Basic logic django filer
import Mediator from './libs/mediator.min';

const Cl = window.Cl || {};

// globals Mediator, django

// as of Django 2.x we need to check where jQuery is
let djQuery = window.$;

// In case the django variable is defined in another script
if (typeof django !== 'undefined') {
  // eslint-disable-next-line no-undef
  djQuery = django.jQuery;
}

window.django = {
  jQuery: djQuery
};

// mediator init
Cl.mediator = new Mediator();

function initFilerInput() {
  let showErrorTimeout;

  function filerShowError(message) {
    const messages = $('.messagelist');
    const header = $('#header');
    const filerErrorClass = 'js-filer-error';
    const tpl = `<ul class="messagelist"><li class="error ${filerErrorClass}">{msg}</li></ul>`;
    const msg = tpl.replace('{msg}', message);

    if (messages.length) {
      messages.replaceWith(msg);
    } else {
      header.after(msg);
    }

    if (showErrorTimeout) {
      clearTimeout(showErrorTimeout);
    }

    showErrorTimeout = setTimeout(() => {
      $(`.${filerErrorClass}`).remove();
    }, 5000);
  }

  window.filerShowError = filerShowError;

  // Focal point logic init
  if (Cl.FocalPoint) {
    // eslint-disable-next-line no-new
    new Cl.FocalPoint();
  }

  // Toggler init
  if (Cl.Toggler) {
    // eslint-disable-next-line no-new
    new Cl.Toggler();
  }
}

function loadFilerInput($) {
  $(initFilerInput);
}

(loadFilerInput(djQuery));
